<template>
  <div class="table">
    <div class="tablebox">
      <el-table
        :data="
          tableData
        "
        style="width: 100%"
        border
      >
        <el-table-column prop="companyName" label="访问企业"> </el-table-column>
        <el-table-column prop="visitorContent" label="来访目的">
        </el-table-column>
        <el-table-column prop="visitorName" label="来访人姓名">
        </el-table-column>
        <el-table-column prop="visitorPhone" label="来访人电话">
        </el-table-column>
        <el-table-column prop="createTime" label="访客时间">
        </el-table-column>

        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              查看
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="查看"
      :visible.sync="dialogVisible"
      :show-close="false"
      width="50%"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="150px"
        :model="formLabelAlign"
      >
        <!-- <el-form-item label="联系人">
          <el-input
            v-model="formLabelAlign.contacts"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系人电话">
          <el-input
            v-model="formLabelAlign.contactsPhone"
            :disabled="true"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="来访目的">
          <el-input
            v-model="formLabelAlign.visitorContent"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="来访人姓名">
          <el-input
            v-model="formLabelAlign.visitorName"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="来访电话">
          <el-input
            v-model="formLabelAlign.visitorPhone"
            :disabled="true"
          ></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetsysVisitorsRecord, DeleteRecorddeleteById } from "@/request/api";
export default {
  name: "HtglEnterprisetable4",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        contacts: "",
        contactsPhone: "",
        visitorContent: "",
        visitorName: "",
        visitorPhone: "",
      },
      currentPage: 1, //分页
      pageNum: 1,
      pageSize: 10,
      total: 1,
    };
  },

  methods: {
    handleUpdate(v) {
      this.formLabelAlign.contacts = v.contacts;
      this.formLabelAlign.contactsPhone = v.contactsPhone;
      this.formLabelAlign.visitorContent = v.visitorContent;
      this.formLabelAlign.visitorName = v.visitorName;
      this.formLabelAlign.visitorPhone = v.visitorPhone;
      this.dialogVisible = true;
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
    // 删除
    handleDelete(v) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeleteRecorddeleteById({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.dialogVisible = false;
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 游客列表
    seelist() {
      GetsysVisitorsRecord({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        console.log(res,111);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.seelist();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>