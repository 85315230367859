<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="企业诉求" name="1">
        <Enterprisetable1 />
      </el-tab-pane>
      <el-tab-pane label="投诉举报" name="2">
        <Enterprisetable2 />
      </el-tab-pane>
      <el-tab-pane label="企业纪录" name="3">
        <Enterprisetable3 />
      </el-tab-pane>
      <el-tab-pane label="访客表单" name="4">
        <Enterprisetable4 />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import Enterprisetable1 from "../../components/enterprise/enterprisetable1.vue";
import Enterprisetable2 from "../../components/enterprise/enterprisetable2.vue";
import Enterprisetable3 from "../../components/enterprise/enterprisetable3.vue";
import Enterprisetable4 from "../../components/enterprise/enterprisetable4.vue";
export default {
  name: "HtglTableenterprise",

  data() {
    return {
      activeName: "1",
    };
  },

  mounted() {},

  methods: {
    handleClick(tab, event) {
      // console.log(tab, event); HtglTableadministration
    },
  },
  components: {
    Enterprisetable1,
    Enterprisetable2,
    Enterprisetable3,
    Enterprisetable4,
  },
};
</script>

<style lang="scss" scoped>
</style>