<template>
  <div class="table">
    <div class="tablebox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="complainants" label="投诉人"> </el-table-column>
        <el-table-column prop="complainantsPhone" label="投诉人电话">
        </el-table-column>
        <el-table-column prop="respondent" label="被投诉人姓名">
        </el-table-column>
        <el-table-column prop="respondentCompany" label="被投诉人单位">
        </el-table-column>
        <el-table-column prop="respondentContent" label="投诉内容">
        </el-table-column>
        <!-- <el-table-column prop="img" label="图片">
          <template slot-scope="scope">
            <img
              :src="scope.row.image"
              alt=""
              style="width: 100px; height: 100px"
            />
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              查看
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="查看"
      :visible.sync="dialogVisible"
      :show-close="false"
      width="50%"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="120px"
        :model="formLabelAlign"
      >
        <!-- <el-form-item label="举报标题">
          <el-input v-model="formLabelAlign.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="举报正文">
          <el-input v-model="formLabelAlign.type" :disabled="true"></el-input>
        </el-form-item>
        
         complainants: "",
        complainantsPhone: "",
        respondent: "",
        respondentCompany: "",
        respondentContent: "",
        respondentUrl:"" -->
        <el-form-item label="投诉人">
          <el-input
            v-model="formLabelAlign.complainants"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="投诉电话">
          <el-input
            v-model="formLabelAlign.complainantsPhone"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="被投诉人姓名">
          <el-input
            v-model="formLabelAlign.respondent"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="被投诉人单位">
          <el-input
            v-model="formLabelAlign.respondentCompany"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="投诉内容">
          <el-input
            v-model="formLabelAlign.respondentCompany"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <template>
            <img
              :src="item"
              v-for="(item, index) in formLabelAlign.respondentUrl"
              :key="index"
              alt=""
              style="width: 100px; height: 100px"
            />
          </template>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetsysCompanyIdea,
  DeletedeleteById,
  GetsysCompanyComplaint,
} from "@/request/api";
export default {
  name: "HtglEnterprisetable2",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        complainants: "",
        complainantsPhone: "",
        respondent: "",
        respondentCompany: "",
        respondentContent: "",
        respondentUrl: "",
      },
      currentPage: 1, //分页
      pageNum: 1,
      pageSize: 10,
      total: 1,
    };
  },

  methods: {
    handleUpdate(v) {
      // console.log(v);
      this.formLabelAlign.complainants = v.complainants;
      this.formLabelAlign.complainantsPhone = v.complainantsPhone;
      this.formLabelAlign.respondent = v.respondent;
      this.formLabelAlign.respondentCompany = v.respondentCompany;
      this.formLabelAlign.respondentContent = v.respondentContent;
      this.formLabelAlign.respondentUrl = v.respondentUrl.split(",");
      this.dialogVisible = true;
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
    // 删除
    handleDelete(v) {
      // console.log(v);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeletedeleteById({
            id: v.id,
          }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.dialogVisible = false;
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 诉求列表
    seelist() {
      GetsysCompanyComplaint({
        companyName: "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.seelist();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>