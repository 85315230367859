<template>
  <div class="table">
    <div class="tablebox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="reportDate" label="执法时间"> </el-table-column>
        <el-table-column prop="createTime" label="上传时间"> </el-table-column>
        <el-table-column prop="title" label="执法人员姓名"> </el-table-column>
        <el-table-column prop="companyName" label="公司名称"> </el-table-column>
        <el-table-column prop="content" label="执法内容"> </el-table-column>
        <el-table-column prop="lawType" label="处罚类型"></el-table-column>
        <!-- <el-table-column prop="imageUrl" label="图片">
          <template slot-scope="scope">
            <img
              :src="scope.row.imageUrl"
              alt=""
              style="width: 100px; height: 100px"
            />
          </template>
        </el-table-column> -->
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              查看
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageNum"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 弹窗 -->
    <el-dialog
      title="查看"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="标题">
          <el-input v-model="formLabelAlign.title" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="公司名称">
          <el-input
            v-model="formLabelAlign.companyName"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="执法内容">
          <el-input
            v-model="formLabelAlign.content"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="处罚类型">
          <el-input
            v-model="formLabelAlign.lawType"
            :disabled="true"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片">
          <template>
            <img
              :src="item"
              v-for="(item, index) in formLabelAlign.imageUrl"
              :key="index"
              alt=""
              style="width: 100px; height: 100px"
            />
          </template>
        </el-form-item>
        <el-form-item label="视频">
          <div v-html="sp"></div>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetsysCompanyReportLawRecordlist } from "@/request/api";

export default {
  name: "HtglEnterprisetable3",

  data() {
    return {
      input: "", //搜索内容  GetsysCompanyReportLawRecordlist
      tableData: [
        // img: "https://img2.baidu.com/it/u=1814268193,3619863984&fm=253&app=138&size=w931&n=0&f=JPEG&fmt=auto?sec=1657213200&t=ec2ead1bf39998a3eaf6623b8a44941e",
      ],
      dialogVisible: false, //弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        title: "",
        companyName: "",
        content: "",
        lawType: "",
        imageUrl: "",
        videoUrl: "",
      },
      currentPage: 1, //分页
      pageNum: 1,
      pageSize: 10,
      total: 1,
      sp: "",
    };
  },

  methods: {
    handleUpdate(v) {
      this.formLabelAlign.title = v.title;
      this.formLabelAlign.companyName = v.companyName;
      this.formLabelAlign.content = v.content;
      this.formLabelAlign.lawType = v.lawType;
      this.formLabelAlign.imageUrl = v.imageUrl.split(",");
      this.formLabelAlign.videoUrl = v.videoUrl;
      this.sp = `<video controls="controls" preload="auto" width="100%" height="500px" >
               <source src="${
                 this.formLabelAlign.videoUrl.split(",")[0]
               }" type="video/mp4" />
             </video>`;
      this.dialogVisible = true;
      // console.log(this.formLabelAlign.video);
    },
    //点击确认
    confirm() {
      this.formLabelAlign.name = "";
      this.formLabelAlign.type = "";
      this.formLabelAlign.img = "";
      this.formLabelAlign.video = "";
      this.sp = "";
      this.dialogVisible = false;
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
      this.seelist();
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.pageNum = val;
      this.seelist();
    },
    // 纪录列表
    seelist() {
      GetsysCompanyReportLawRecordlist({
        companyName: "",
        endDate: "",
        keyword: "",
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        startDate: "",
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data.list;
          this.currentPage = res.totalPage;
          this.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.seelist();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>